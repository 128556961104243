import { motion } from "framer-motion";
import { FC, useRef } from "react";
import { Autoplay } from "swiper";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./ServicesSlider.module.scss";

interface ServicesSliderProps {
  reverse: boolean;
  slides: any;
}

const ServicesSlider: FC<ServicesSliderProps> = ({ reverse, slides }) => {
  const swiperRefLocal = useRef<any>();

  const handleMouseEnter = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.stop();
  };

  const handleMouseLeave = () => {
    swiperRefLocal?.current?.swiper?.autoplay?.start();
  };
  return (
    <div
      className={styles.swiperContainer}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Swiper
        ref={swiperRefLocal}
        modules={[Autoplay]}
        loop={true}
        speed={2000}
        // grabCursor={true}
        freeMode={false}
        autoplay={{
          delay: 0,

          reverseDirection: reverse,
          pauseOnMouseEnter: true,
          disableOnInteraction: true,
        }}
        breakpoints={{
          1650: {
            slidesPerView: 4,
            spaceBetween: 10,
          },
          1350: {
            slidesPerView: 3.5,
            spaceBetween: 10,
          },

          900: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          200: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
        }}
      >
        {slides.map((slide: any, index: number) => {
          return (
            <SwiperSlide key={index}>
              <motion.div
                whileHover={{
                  scale: 1.2,
                }}
                className={styles.serviceCard}
              >
                <h6>{slide.name}</h6>
                <p>{slide.description}</p>
              </motion.div>
            </SwiperSlide>
          );
        })}
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.serviceCard}>
            <h6>boody</h6>
            <p>saleh</p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ServicesSlider;
