import { Box, InputBase, Typography } from "@mui/material";
import { Field, FieldProps } from "formik";
import { FC } from "react";

interface CustomTextFieldProps {
  name: string;
  multiline?: boolean;
  style?: string;
  placeHolder?: string;
  type?: string;
}

const CustomTextField: FC<CustomTextFieldProps> = ({
  name,
  multiline,
  style,
  placeHolder,
  type,
}) => {
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <Box
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <InputBase
            type={type ? type : "text"}
            fullWidth
            multiline={multiline}
            sx={{
              backgroundColor: "#ffffff85",
              boxShadow: "1px 3px 20px 0px rgba(0, 0, 0, 0.04)",
              // height: {
              //   xs: style === "small" ? "25px" : "50px",
              //   md: style === "small" ? "30px" : "55px",
              //   lg: style === "small" ? "30px" : "60px",
              //   xl: style === "small" ? "30px" : "65px",
              // },
              borderRadius: "10px",
              padding: {
                xs: "8px",
                md: style === "small" ? "12px" : "10px",
                lg: style === "small" ? "12px" : "14px",
                xl: style === "small" ? "12px" : "16px",
              },
              fontSize: {
                xs: "16px",
                md: style === "small" ? "20px" : "18px",
                lg: style === "small" ? "20px" : "20px",
                xl: style === "small" ? "20px" : "24px",
              },
              ".MuiInputBase-input": {
                "::placeholder": {
                  color: "black",
                  opacity: style === "small" ? 1 : 0.5,
                },
              },
            }}
            minRows={multiline ? 4 : 1}
            placeholder={placeHolder}
            value={field.value}
            onChange={field.onChange}
            // error={meta.touched && Boolean(meta.error)}

            name={name}
          />
          {meta.touched && Boolean(meta.error) && (
            <Typography
              sx={{
                color: "red",
                fontSize: { xs: "12px !important", md: "16px !important" },
                position: "absolute",
                bottom: multiline ? "-15%" : "-30%",
                left: "10px",
              }}
            >
              {meta.error}
            </Typography>
          )}
        </Box>
      )}
    </Field>
  );
};

export default CustomTextField;
