import React, { useState, FC } from "react";
import PersonalCard from "../PersonalCard/PersonalCard";
import styles from "./OurTeam.module.scss";
import { motion } from "framer-motion";
import { useMediaQuery } from "@mui/material";
import { splitIntoArrays } from "../../helpers/splitIntoFour";

interface OurTeamProps {
  members: any;
}

const OurTeam: FC<OurTeamProps> = ({ members }) => {
  // This  state of the card that is currently expanded. It is initially set to null.
  const [openCard, setOpenCard] = useState<number | null>(null);

  // A hook from MUI that returns true if the screen width is less than or equal to 1000px.
  const matches = useMediaQuery("(max-width:1000px)");

  // This variable is used to determine if the PersonalCard component is expandable or not based on the width of the screen.
  const expandable = !matches;

  // This function splits the members array into smaller arrays, each containing a maximum of four members.
  let membersArray: any = [];

  membersArray = splitIntoArrays(members);

  return (
    <section className={styles.team} id="team">
      <motion.h3
        initial={{ x: "-100%" }}
        whileInView={{ x: 0 }}
        transition={{ type: "tween", duration: 0.7 }}
        viewport={{ once: true }}
      >
        Meet Our Team
      </motion.h3>

      {/* This loop iterates over the membersArray and returns a grid of PersonalCards */}
      {membersArray.map((array: any, i: number) => {
        return (
          <motion.div
            initial={{ x: i % 2 === 0 ? "100%" : "-100%" }}
            whileInView={{ x: 0 }}
            transition={{ type: "tween", duration: 0.5 }}
            viewport={{ once: true }}
            className={styles.grid}
            key={i}
          >
            {/* This loop iterates over each member in the current array and returns a PersonalCard */}
            {array.map((member: any, index: number) => {
              return (
                <div className={styles.item} key={index}>
                  <PersonalCard
                    expandable={expandable}
                    expanded={openCard}
                    setOpenCard={setOpenCard}
                    direction="ltr"
                    id={Number(`${i}${index}`)}
                    name={member.name}
                    title={member.title}
                    image={member.image}
                  />
                </div>
              );
            })}
          </motion.div>
        );
      })}
    </section>
  );
};

export default OurTeam;
