import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useScrollToTop = (dependencies: any[]) => {
  const location = useLocation();

  const scrollToTop = () => {
    if (location.hash) return;
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, [location, ...dependencies, window.innerHeight, window.outerHeight]);
};

export default useScrollToTop;
