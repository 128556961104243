import { Container, useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { SocialIcon } from "react-social-icons";
import logo from "../../assets/logo.png";
import useGetData from "../../hooks/useGetData";

import { useNavigate } from "react-router-dom";
import styles from "./Footer.module.scss";

const Footer = () => {
  const navigate = useNavigate();
  const matches = useMediaQuery("(max-width:1685px)");
  const urls: string[] = useMemo(() => ["/General/setting"], []);
  const { data, loading } = useGetData(urls);

  return (
    <motion.div
      className={styles.footer}
      initial={{ y: 250, opacity: 0.5 }}
      transition={{ duration: 0.8 }}
      animate={{ y: 0, opacity: 1 }}
      viewport={{ once: true }}
    >
      <Container
        maxWidth={false}
        sx={{
          display: "flex",
          maxWidth: "90vw",
        }}
      >
        <main>
          <div className={styles.logo}>
            <img alt="logo" src={logo} />
          </div>
          <p className={styles.description}>
            {data && data[0] && data[0].footerText}
          </p>
          <div className={styles.socials}>
            {data && data[0] && data[0].linkedin && (
              <SocialIcon
                target="_blank"
                className={styles.social}
                url={data[0].linkedin}
                style={{
                  width: matches ? "30px" : "40px",
                  height: matches ? "30px" : "40px",
                }}
              />
            )}
            {data && data[0] && data[0].youtube && (
              <SocialIcon
                target="_blank"
                className={styles.social}
                url={data[0].youtube}
                style={{
                  width: matches ? "30px" : "40px",
                  height: matches ? "30px" : "40px",
                }}
              />
            )}
            {data && data[0] && data[0].twitter && (
              <SocialIcon
                target="_blank"
                className={styles.social}
                url={data[0].twitter}
                style={{
                  width: matches ? "30px" : "40px",
                  height: matches ? "30px" : "40px",
                }}
              />
            )}
            {data && data[0] && data[0].facebook && (
              <SocialIcon
                target="_blank"
                className={styles.social}
                url={data[0].facebook}
                style={{
                  width: matches ? "30px" : "40px",
                  height: matches ? "30px" : "40px",
                }}
              />
            )}
            {data && data[0] && data[0].instagram && (
              <SocialIcon
                target="_blank"
                className={styles.social}
                url={data[0].instagram}
                style={{
                  width: matches ? "30px" : "40px",
                  height: matches ? "30px" : "40px",
                }}
              />
            )}
          </div>
        </main>
        <div className={styles.listsContainer}>
          <ul>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.title}
              onClick={() => {
                navigate("/");
              }}
            >
              EG-LAS{" "}
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/");
              }}
            >
              About Us
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/#vision");
              }}
            >
              Vision & Mission
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/#trustees");
              }}
            >
              Board of Trustees
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/#partners");
              }}
            >
              Our Partners
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/#centers");
              }}
            >
              Centers & Facilities
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/Gallery");
              }}
            >
              Gallery
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/#chart");
              }}
            >
              Organizational Charts
            </motion.li>
          </ul>

          <ul>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.title}
              onClick={() => {
                navigate("/URAF");
              }}
            >
              URAF
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/URAF");
              }}
            >
              About Us
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/URAF#vision");
              }}
            >
              Vision & Mission
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/URAF#services");
              }}
            >
              Our Services
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/URAF#team");
              }}
            >
              Our Team
            </motion.li>
          </ul>

          <ul>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.title}
              onClick={() => {
                navigate("/CTCLAS");
              }}
            >
              CTC - LAS
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/CTCLAS");
              }}
            >
              About Us
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/CTCLAS#vision");
              }}
            >
              Vision & Mission
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/CTCLAS#team");
              }}
            >
              Our Team
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/CTCLAS#training");
              }}
            >
              Training
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/CTCLAS#consultation");
              }}
            >
              Consulting
            </motion.li>
          </ul>
          <ul>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.title}
              onClick={() => {
                navigate("/IACUC");
              }}
            >
              IACUC
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/IACUC");
              }}
            >
              About Us
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/IACUC#vision");
              }}
            >
              Vision & Mission
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/IACUC#team");
              }}
            >
              Our Team
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/IACUC#application");
              }}
            >
              Application Forms{" "}
            </motion.li>
            <motion.li
              whileHover={{
                scale: 1.2,
                transition: { duration: 0.2 },
              }}
              // whileTap={{ scale: 0.9 }}
              className={styles.item}
              onClick={() => {
                navigate("/IACUC#meeting");
              }}
            >
              Meeting Minutes
            </motion.li>
          </ul>
        </div>
      </Container>
    </motion.div>
  );
};

export default Footer;
