import BgCircle from "../../components/BgCircle/BgCircle";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import MediaCard from "../../components/MediaCard/MediaCard";
import OurTeam from "../../components/OurTeam/OurTeam";
import TextCard from "../../components/TextCard/TextCard";
import useGetData from "../../hooks/useGetData";
import { Box, Button, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { motion } from "framer-motion";
import containsNull from "../../helpers/containsNull";
import { useEffect, useMemo } from "react";
import Application from "../../components/Application/Application";
import Header from "../../components/Header/Header";
import styles from "./IACUC.module.scss";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import NoData from "../../components/NoData/NoData";
import useScrollToTop from "../../hooks/useScrollToTop";

const IACUC = () => {
  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", id: "vision" },
      { name: "Meeting Minutes", id: "meeting" },
      { name: "Application Form", id: "application" },
      { name: "Our Team", id: "team" },
    ],
    []
  );
  // const matches = useMediaQuery("(max-width:1350px)");
  const applicationMatches = useMediaQuery("(max-width:1200px)");
  const urls: string[] = useMemo(
    () => [
      "/IACUC/mainInfo",
      "/IACUC/meetingLink",
      "/URAF/ourTeam",
      "/IACUC/sections",
    ],
    []
  );

  const { data, loading } = useGetData(urls);
  const location = useLocation();
  useEffect(() => {
    const node = document.getElementById(location.hash.split("#")[1]);
    window.scrollTo({
      top: node?.offsetTop,
      behavior: "smooth",
    });
  }, [loading, location.hash]);
  useScrollToTop([loading]);
  return (
    <Box sx={{ position: "relative" }}>
      <BgCircle
        bg={"rgba(26, 171, 174,.1)"}
        radius={800}
        left={"0"}
        top={"-20vh"}
      />

      <BgCircle
        bg={"rgba(26, 171, 174,.1)"}
        radius={800}
        right={"0"}
        top={"140vh"}
      />

      <BgCircle
        bg={"rgba(26, 171, 174,.1)"}
        radius={1250}
        left={"-50vw"}
        bottom={"0"}
      />

      <BgCircle
        bg={"rgba(26, 171, 174,.1)"}
        radius={800}
        left={"-20vw"}
        top={"226vh"}
      />

      <div className={styles.IACUC}>
        <Header navItems={navItems} />
        {loading ? (
          <Loader color="rgb(26, 171 ,174)" />
        ) : containsNull(data) ? (
          <NoData />
        ) : (
          data && (
            <>
              <Container
                maxWidth={false}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "100px", md: "120px", lg: "150px", xl: "180px" },
                  maxWidth: "90vw",
                }}
              >
                <div className={styles.header}>
                  <motion.div
                    initial={{ x: "-100%" }}
                    whileInView={{ x: "0" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                  >
                    <MediaCard media="image" image={data[0].image} />
                  </motion.div>
                  <motion.div
                    initial={{ x: "100%", opacity: "0" }}
                    whileInView={{ x: 0, opacity: "1" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.description}
                  >
                    <h2>{data[0]?.title}</h2>

                    <p>{data[0]?.headerDesc}</p>
                  </motion.div>
                </div>
                {data[0] && (
                  <section className={styles.vision} id="vision">
                    <motion.h3
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 0.75 }}
                      viewport={{ once: true }}
                    >
                      Vision & Mission
                    </motion.h3>
                    <div className={styles.content}>
                      <motion.div
                        initial={{ x: "-100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color=" #1AABAE"
                          title="Vision"
                          content={data[0]?.vision}
                        />
                      </motion.div>
                      <motion.div
                        initial={{ x: "100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color="#1AABAE"
                          title="Mission"
                          content={data[0]?.mission}
                        />
                      </motion.div>
                    </div>
                  </section>
                )}
                {data[1] && data[1].length > 0 && (
                  <section className={styles.meeting} id="meeting">
                    <motion.h3
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 0.75 }}
                      viewport={{ once: true }}
                    >
                      IACUC Meeting Minutes
                    </motion.h3>

                    <div className={styles.meetingItems}>
                      {data[1].map((item: any, index: number) => {
                        return (
                          <div className={styles.item} key={index}>
                            <motion.div
                              initial={{ x: "-100%" }}
                              whileInView={{ x: 0 }}
                              transition={{ type: "tween", duration: 0.75 }}
                              viewport={{ once: true }}
                              className={styles.link}
                              key={item.id}
                              onClick={() => {
                                window.open(item.link, "_blank");
                              }}
                            >
                              {item.name}{" "}
                            </motion.div>

                            <motion.div
                              initial={{ x: "100%" }}
                              whileInView={{ x: 0 }}
                              transition={{ type: "tween", duration: 0.75 }}
                              viewport={{ once: true }}
                            >
                              <Button
                                variant="contained"
                                color="secondary"
                                sx={{
                                  backgroundColor: "#1aabae38",
                                  color: "#1AABAE",
                                  width: "170px",
                                  height: "65px",
                                  borderRadius: "25px",
                                  boxShadow:
                                    "0.7305867075920105px 1.461173415184021px 10.958800315856934px 0px #0000001A",
                                }}
                                onClick={() => {
                                  window.open(item.link, "_blank");
                                }}
                              >
                                View
                              </Button>
                            </motion.div>
                          </div>
                        );
                      })}
                    </div>
                  </section>
                )}
                <section className={styles.application} id="application">
                  {data[3] &&
                    data[3].length > 0 &&
                    data[3].map((item: any) => {
                      return (
                        <div key={item.id}>
                          <motion.h3
                            initial={{ x: "-100%" }}
                            whileInView={{ x: 0 }}
                            transition={{ type: "tween", duration: 0.75 }}
                            viewport={{ once: true }}
                          >
                            {item.name}
                          </motion.h3>
                          {item.description && (
                            <motion.p
                              initial={{ x: "-100%" }}
                              whileInView={{ x: 0 }}
                              transition={{ type: "tween", duration: 0.75 }}
                              viewport={{ once: true }}
                            >
                              {item.description}
                            </motion.p>
                          )}
                          {item.links && item.links.length > 0 && (
                            <div className={styles.applicationItem}>
                              {item.links.map((link: any, index: number) => {
                                return (
                                  <motion.div
                                    key={index}
                                    initial={{
                                      x: index % 2 === 0 ? "-100%" : "100%",
                                    }}
                                    whileInView={{ x: 0 }}
                                    transition={{
                                      type: "tween",
                                      duration: 0.75,
                                    }}
                                    viewport={{ once: true }}
                                    style={{
                                      width: "100%",

                                      display: "flex",
                                      justifyContent: applicationMatches
                                        ? "center"
                                        : "flex-start",
                                    }}
                                  >
                                    <Application
                                      name={link.name}
                                      id={link.id}
                                      link={link.link}
                                    />
                                  </motion.div>
                                );
                              })}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </section>
                {data[2] && <OurTeam members={data[2]} />}{" "}
              </Container>
              <Footer />
            </>
          )
        )}
      </div>
    </Box>
  );
};

export default IACUC;
