import { useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { motion } from "framer-motion";
import BgCircle from "../../components/BgCircle/BgCircle";
import ConsultingForm from "../../components/ConsultingForm/ConsultingForm";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import MediaCard from "../../components/MediaCard/MediaCard";
import OurTeam from "../../components/OurTeam/OurTeam";
import TextCard from "../../components/TextCard/TextCard";
import TrainingAccordion from "../../components/TrainingAccordion/TrainingAccordion";
import useGetData from "../../hooks/useGetData";
import containsNull from "../../helpers/containsNull";
import { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import image from "../../assets/banner.png";
import Header from "../../components/Header/Header";
import styles from "./CTCLAS.module.scss";
import NoData from "../../components/NoData/NoData";
import useScrollToTop from "../../hooks/useScrollToTop";

const CTCLAS = () => {
  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", id: "vision" },
      { name: "Training", id: "training" },
      { name: "Ask For Consultation", id: "consultation" },
      { name: "Our Team", id: "team" },
    ],
    []
  );
  const matches = useMediaQuery("(max-width:1350px)");
  const matchesSm = useMediaQuery("(max-width:1000px)");

  const urls: string[] = useMemo(
    () => [
      "/CTC/mainInfo",
      "/CTC/servicesTypes",
      "/CTC/ourTeam",
      "/CTC/training",
    ],
    []
  );
  const { data, loading } = useGetData(urls);
  const location = useLocation();
  useEffect(() => {
    const node = document.getElementById(location.hash.split("#")[1]);
    window.scrollTo({
      top: node?.offsetTop,
      behavior: "smooth",
    });
  }, [loading, location.hash]);

  useScrollToTop([loading]);

  return (
    <>
      <BgCircle
        bg={"rgba(112, 48, 160, 0.1)"}
        radius={1250}
        left={matchesSm ? "0vw" : "-50vw"}
        top={"-64vh"}
      />

      <BgCircle
        bg={"rgba(112, 48, 160, 0.1)"}
        radius={1250}
        left={matchesSm ? "0vw" : "-50vw"}
        top={"182vh"}
      />

      {!matches && (
        <BgCircle
          bg={"rgba(112, 48, 160, 0.1)"}
          radius={1250}
          left={matchesSm ? "0vw" : "-50vw"}
          bottom={"0px"}
        />
      )}

      <BgCircle
        bg={"rgba(112, 48, 160, 0.1)"}
        radius={1250}
        right={matchesSm ? "0vw" : "-50vw"}
        top={"100vh"}
      />

      {!matches && (
        <BgCircle
          bg={"rgba(112, 48, 160, 0.1)"}
          radius={1250}
          right={matchesSm ? "0vw" : "-50vw"}
          bottom={"100vh"}
        />
      )}

      <div className={styles.CTCLAS}>
        <Header navItems={navItems} />
        {loading ? (
          <Loader color="#7030a0" />
        ) : containsNull(data) ? (
          <NoData />
        ) : (
          data && (
            <>
              <Container
                maxWidth={false}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "100px", md: "120px", lg: "150px", xl: "180px" },
                  maxWidth: "90vw",
                }}
              >
                <div className={styles.header}>
                  <motion.div
                    initial={{ x: "-100%" }}
                    whileInView={{ x: "0" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.imageContainer}
                  >
                    <MediaCard
                      media="image"
                      secondaryStyle={true}
                      image={data[0].image}
                    />
                  </motion.div>
                  <motion.div
                    initial={{ x: "100%", opacity: "0" }}
                    whileInView={{ x: 0, opacity: "1" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.description}
                  >
                    <h2>{data[0]?.title} </h2>
                    <h2>CTC-LAS</h2>
                    <p>{data[0]?.headerDesc}</p>
                  </motion.div>
                </div>
                {data[0] && (
                  <section className={styles.vision} id="vision">
                    <motion.h3
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 0.75 }}
                      viewport={{ once: true }}
                    >
                      Vision & Mission
                    </motion.h3>
                    <div className={styles.content}>
                      <motion.div
                        initial={{ x: "-100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color=" #7030A0"
                          title="Vision"
                          content={data[0]?.vision}
                        />
                      </motion.div>
                      <motion.div
                        initial={{ x: "100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color=" #7030A0"
                          title="Mission"
                          content={data[0]?.mission}
                        />
                      </motion.div>
                    </div>
                  </section>
                )}
                {data && data[3] && (
                  <section className={styles.training} id="training">
                    <motion.div
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      viewport={{ once: true }}
                      transition={{ type: "tween", duration: 0.75 }}
                      className={styles.item}
                    >
                      <h3>Training</h3>
                      <p>
                        We are a group of experts and members of the private
                        sector committed{" "}
                      </p>
                      <div className={styles.trainingItems}>
                        {data[3].map((item: any, index: number) => {
                          return (
                            <TrainingAccordion
                              key={item.id}
                              name={item.name}
                              subTraining={item.subTraining}
                            />
                          );
                        })}
                      </div>
                    </motion.div>
                    <motion.div
                      initial={{ x: "100%" }}
                      whileInView={{ x: 0 }}
                      viewport={{ once: true }}
                      transition={{ type: "tween", duration: 0.75 }}
                      className={styles.item}
                    >
                      <div className={styles.imageContainer}>
                        {" "}
                        <div className={styles.border}>
                          <svg
                            width="359"
                            height="603"
                            viewBox="0 0 359 603"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M250.524 595.01C315.075 530.413 351.34 442.802 351.34 351.448C351.34 260.095 315.075 172.483 250.524 107.887C185.973 43.29 98.4231 7.00001 7.13408 7"
                              stroke="#7030A0"
                              strokeWidth="13.9969"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                        <img
                          // fill
                          src={image}
                          className={styles.image}
                          alt="banner"
                        />
                      </div>
                    </motion.div>
                  </section>
                )}
                <section className={styles.consultation} id="consultation">
                  <motion.div
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0 }}
                    viewport={{ once: true }}
                    transition={{ type: "tween", duration: 0.75 }}
                    className={styles.item}
                    // style={{ marginTop: "175px" }}
                  >
                    <div className={styles.imageContainer}>
                      <div className={styles.imageContainer}>
                        {" "}
                        <div className={styles.border}>
                          <svg
                            width="378"
                            height="635"
                            viewBox="0 0 378 635"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M114.133 627.223C46.1772 559.167 8.00001 466.864 8 370.619C7.99999 274.374 46.1771 182.071 114.133 114.015C182.089 45.9599 274.256 7.72673 370.36 7.72672"
                              stroke="#7030A0"
                              strokeWidth="14.7351"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                        <img
                          // fill
                          src={image}
                          className={styles.image}
                          alt="banner"
                        />
                      </div>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ x: "100%" }}
                    whileInView={{ x: 0 }}
                    viewport={{ once: true }}
                    transition={{ type: "tween", duration: 0.75 }}
                    className={styles.item}
                  >
                    <h3>Consulting</h3>
                    <p>
                      Ask for service ,We are a group of experts and members of
                      the private sector committed{" "}
                    </p>
                    <ConsultingForm />
                  </motion.div>
                </section>
                {data[2] && <OurTeam members={data[2]} />}
              </Container>
              <Footer />
            </>
          )
        )}
      </div>
    </>
  );
};

export default CTCLAS;
