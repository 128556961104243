import React, { FC } from "react";

interface BgCircleProps {
  radius: number;
  bg: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

const BgCircle: FC<BgCircleProps> = ({
  radius,
  bg,
  top,
  right,
  bottom,
  left,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        width: radius,
        height: radius,
        borderRadius: "50%",
        background: bg,
        filter: "blur(131.687px)",
        top: top || "auto",
        bottom: bottom || "auto",
        right: right || "auto",
        left: left || "auto",
        zIndex: -1,
        overflow: "hidden",
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    ></div>
  );
};

export default BgCircle;
