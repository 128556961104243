import { Button } from "@mui/material";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import { FaPlay } from "react-icons/fa";
import overlayImage from "../../assets/banner.png";
import AnimateY from "../Animation/AnimateY";
import styles from "./MediaCard.module.scss";

interface MediaCardProps {
  media: string;
  secondaryStyle?: boolean;
  image?: string;
  video?: string;
}

const MediaCard: FC<MediaCardProps> = ({
  media,
  secondaryStyle,
  image,
  video,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <div className={media === "video" ? styles.videoCard : styles.imageCard}>
      {media === "video" ? (
        <div className={styles.videoContainer}>
          <AnimatePresence>
            {open && (
              <motion.div
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ height: "100%" }}
              >
                <video
                  id="video"
                  controls
                  autoPlay={open}
                  width={"100%"}
                  height={"100%"}
                >
                  <source
                    src={video}
                    type={"video/" + video?.split(".").pop()}
                  />
                </video>
              </motion.div>
            )}

            {!open && (
              <motion.div
                initial={{ opacity: 0.5 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                style={{ height: "100%" }}
              >
                <img
                  src={overlayImage}
                  alt="card"
                  className={open ? styles.hide : styles.image}
                />
                <Button
                  variant="contained"
                  sx={{
                    zIndex: 1,
                    display: open ? "none" : "flex",
                    width: { xs: "50px", md: "63px", xl: "100px" },
                    height: { xs: "50px", md: "63px", xl: "100px" },
                    padding: { xs: "5px", md: "10px", lg: "15px" },
                    borderRadius: "50%",
                    minWidth: "unset",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%) !important",
                    justifyContent: "center",
                    alignItems: "center",
                    "& svg": {
                      fontSize: { xs: "12px", md: "12px", xl: "30px" },
                      transform: { md: "translate(1px,1px)" },
                    },
                    "&::after": {
                      content: '""',
                      position: "absolute",
                      zIndex: -1,
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "120%",
                      height: "120%",
                      borderRadius: "50%",
                      border: {
                        xs: "5px solid rgba(255, 255, 255, 0.66)",
                        md: "7px solid rgba(255, 255, 255, 0.66)",
                        xl: "11px solid rgba(255, 255, 255, 0.66)",
                      },

                      // background: "rgba(255, 255, 255, 0.66)",
                    },
                  }}
                  onClick={() => setOpen(true)}
                >
                  <FaPlay />
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      ) : (
        image && <img src={image} alt="ovelay" />
      )}
      <AnimateY
        duration={1}
        className={
          secondaryStyle
            ? `${styles.box} ${styles.one} ${styles.purple}`
            : `${styles.box} ${styles.one}`
        }
      ></AnimateY>
      <AnimateY
        duration={1}
        className={`${styles.box} ${styles.two}`}
      ></AnimateY>
      <AnimateY
        duration={1}
        className={`${styles.box} ${styles.three}`}
      ></AnimateY>
    </div>
  );
};

export default MediaCard;
