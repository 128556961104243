import { Box, useMediaQuery } from "@mui/material";
import { Container } from "@mui/system";
import { motion } from "framer-motion";
import AnimateY from "../../components/Animation/AnimateY";
import BgCircle from "../../components/BgCircle/BgCircle";
import ContactUs from "../../components/ContactUs/ContactUs";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import MediaCard from "../../components/MediaCard/MediaCard";
import MembersMenu from "../../components/MembersMenu/MemberMenu";
import PersonalCard from "../../components/PersonalCard/PersonalCard";
import Slider from "../../components/Slider/Slider";
import TextCard from "../../components/TextCard/TextCard";
import useGetData from "../../hooks/useGetData";
import styles from "./Home.module.scss";
import containsNull from "../../helpers/containsNull";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import logo3 from "../../assets/logo3.png";
import Header from "../../components/Header/Header";
import NoData from "../../components/NoData/NoData";

const Home = () => {
  const matchesSm = useMediaQuery("(max-width:1000px)");
  const [overLayer, setOverLayer] = useState({
    id: 0,
    clicked: false,
  });

  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", id: "vision" },
      { name: "Centers", id: "centers" },
      { name: "Trustees", id: "trustees" },
      { name: "Partners", id: "partners" },
      { name: "Organizational chart", id: "chart" },
      { name: "Gallery", link: true, pathname: "/Gallery?page=1" },
    ],
    []
  );
  const navigate = useNavigate();
  const location = useLocation();
  const urls: string[] = useMemo(
    () => [
      "/General/board",
      "/General/partner",
      "/General/setting",
      "/General/gallery",
      "/General/foundations",
    ],
    []
  );
  const { data, loading } = useGetData(urls);

  useEffect(() => {
    const node = document.getElementById(location.hash.split("#")[1]);
    window.scrollTo({
      top: node?.offsetTop,
      behavior: "smooth",
    });
  }, [loading, location.hash]);
  const matches = useMediaQuery("(min-width:1000px)");

  return (
    <div className={styles.Home} style={{ position: "relative" }}>
      <Header navItems={navItems} />

      {loading ? (
        <Loader color="rgb(26, 171 ,174)" />
      ) : containsNull(data) ? (
        <NoData />
      ) : (
        data && (
          <>
            <Container
              maxWidth={false}
              sx={{ position: "relative", maxWidth: "90vw" }}
            >
              <BgCircle
                bg={"rgb(112 48 160 / 15%)"}
                radius={250}
                left={"-50vw"}
                top={"-123vh"}
              />

              <BgCircle
                bg={"rgba(26, 171, 174, 0.1)"}
                radius={1250}
                left={"-78vw"}
                top={"190vh"}
              />

              <BgCircle
                bg={"rgba(26, 171, 174, 0.1)"}
                radius={1250}
                left={"-50vw"}
                bottom="0"
              />

              <BgCircle
                bg={"rgba(26, 171, 174, 0.1)"}
                radius={1250}
                left={"-50vw"}
                bottom="0"
              />

              <main className={styles.main}>
                <div className={styles.flexContainer}>
                  <div className={styles.content}>
                    <motion.div
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 1 }}
                      viewport={{ once: true }}
                      className={styles.item}
                    >
                      {data && (
                        <h1>
                          <span
                            style={{
                              color: "rgba(9, 118, 180, 1)",
                              marginRight: "0.5rem",
                            }}
                          >
                            {data[2]?.aboutUsTitle?.split(" ")[0]}
                          </span>
                          {data[2]?.aboutUsTitle?.split(" ").slice(1).join(" ")}
                        </h1>
                      )}
                      <p>{data[2]?.aboutUsDesc}</p>
                      <Box sx={{ marginRight: { xs: "20%", md: 0 } }}>
                        <ContactUs
                          phone={data[2]?.phone}
                          email={data[2]?.email}
                          address={data[2]?.address}
                        />
                      </Box>
                    </motion.div>
                    <motion.div
                      initial={{ x: "100%" }}
                      whileInView={{ x: "0" }}
                      transition={{ type: "tween", duration: 1 }}
                      viewport={{ once: true }}
                      className={styles.imageContainer}
                    >
                      <MediaCard media="video" video={data[2]?.homeVideo} />
                    </motion.div>
                  </div>
                </div>
                <div className={styles.flexContainer} id="vision">
                  <motion.h3
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "tween", duration: 0.75 }}
                    viewport={{ once: true }}
                    className={styles.title}
                  >
                    Vision & Mission
                  </motion.h3>
                  <div className={styles.content}>
                    <motion.div
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      viewport={{ once: true }}
                      transition={{ type: "tween", duration: 0.75 }}
                    >
                      <TextCard
                        color="#0976B4"
                        title="vision"
                        content={data[2]?.vision}
                      />
                    </motion.div>
                    <motion.div
                      initial={{ x: "100%" }}
                      whileInView={{ x: 0 }}
                      viewport={{ once: true }}
                      transition={{ type: "tween", duration: 0.75 }}
                    >
                      <TextCard
                        color="#0976B4"
                        title="Mission"
                        content={data[2]?.mission}
                      />
                    </motion.div>
                  </div>
                </div>
                <div className={styles.flexContainer} id="centers">
                  <motion.h3
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "tween", duration: 0.75 }}
                    viewport={{ once: true }}
                    className={styles.title}
                  >
                    CENTERS & FACILITES
                  </motion.h3>
                  <motion.div
                    initial={{ y: 300 }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "tween", duration: 0.75 }}
                    viewport={{ once: true }}
                    className={styles.content}
                  >
                    <div
                      className={styles.logoContainer}
                      onClick={() => {
                        navigate("/URAF");
                      }}
                    >
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        className={styles.logo}
                      >
                        <img alt="logo" src={logo1} />
                      </motion.div>
                      <div className={styles.logoName}>URAF</div>
                      <p className={styles.logoDescription}>
                        Ideal environment ensures standard husbandry and ethical
                        care for laboratory animals before,
                      </p>
                    </div>
                    <div
                      className={`${styles.logoContainer} ${styles.middle}`}
                      onClick={() => {
                        navigate("/CTCLAS");
                      }}
                    >
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        className={styles.logo}
                      >
                        <img alt="logo" src={logo2} />
                      </motion.div>
                      <div className={styles.logoName}>CTC - LAS</div>
                      <p className={styles.logoDescription}>
                        Ideal environment ensures standard husbandry and ethical
                        care for laboratory animals before,
                      </p>
                    </div>
                    <div
                      className={styles.logoContainer}
                      onClick={() => {
                        navigate("/IACUC");
                      }}
                    >
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        className={styles.logo}
                      >
                        <img alt="logo" src={logo3} />
                      </motion.div>
                      <div className={styles.logoName}>IACUC</div>
                      <p className={styles.logoDescription}>
                        Ideal environment ensures standard husbandry and ethical
                        care for laboratory animals before,
                      </p>
                    </div>
                  </motion.div>
                </div>
                {!matchesSm && data[0] && (
                  <section className={styles.trustees} id="trustees">
                    <div className={styles.itemOne}>
                      <motion.div
                        initial={{ x: -300 }}
                        whileInView={{ x: "5vw", y: -25 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                        className={styles.one}
                      >
                        <AnimateY duration={1} reverse={true}>
                          <PersonalCard
                            id={data[0][0].id}
                            expandable={false}
                            direction={"rtl"}
                            name={data[0][0]?.name}
                            title={data[0][0]?.title}
                            image={data[0][0]?.image}
                          />
                        </AnimateY>
                      </motion.div>
                      <motion.div className={styles.four}>
                        <motion.div
                          initial={{ x: -300 }}
                          whileInView={{ x: "7vw", y: 55 }}
                          transition={{ type: "tween", duration: 0.5 }}
                          viewport={{ once: true }}
                        >
                          <AnimateY duration={1}>
                            <PersonalCard
                              expandable={false}
                              direction={"rtl"}
                              name={data[0][1]?.name}
                              title={data[0][1]?.title}
                              image={data[0][1]?.image}
                              id={data[0][1]?.id}
                            />
                          </AnimateY>
                        </motion.div>
                      </motion.div>
                    </div>
                    <div className={styles.itemTwo}>
                      <motion.div
                        initial={{ scale: 0 }}
                        whileInView={{ scale: 1 }}
                        transition={{
                          type: "tween",
                          duration: 0.5,
                          delay: 0.2,
                        }}
                        viewport={{ once: true }}
                        className={styles.info}
                      >
                        <div> Meet Our</div> <span> Board Of </span>
                      </motion.div>
                    </div>
                    <div className={styles.three}>
                      <motion.div
                        initial={{ x: 400 }}
                        whileInView={{ x: -150, y: -30 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                        className={styles.two}
                      >
                        <AnimateY duration={1} reverse={true}>
                          <PersonalCard
                            expandable={false}
                            direction={"ltr"}
                            name={data[0][2]?.name}
                            title={data[0][2]?.title}
                            image={data[0][2]?.image}
                            id={data[0][2]?.id}
                          />
                        </AnimateY>
                      </motion.div>
                      <motion.div
                        initial={{ x: 400 }}
                        whileInView={{ x: 0, y: 50 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <AnimateY duration={1}>
                          <PersonalCard
                            expandable={false}
                            direction={"ltr"}
                            name={data[0][3]?.name}
                            title={data[0][3]?.title}
                            image={data[0][3]?.image}
                            id={data[0][3]?.id}
                          />
                        </AnimateY>
                      </motion.div>
                      <motion.div
                        initial={{ x: 400 }}
                        whileInView={{ x: "-21vw", y: 100 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                        className={styles.itemThree}
                      >
                        <AnimateY duration={1}>
                          <PersonalCard
                            expandable={false}
                            direction={"ltr"}
                            name={data[0][4]?.name}
                            title={data[0][4]?.title}
                            image={data[0][4]?.image}
                            id={data[0][4]?.id}
                          />
                        </AnimateY>
                      </motion.div>
                    </div>
                  </section>
                )}
                {matchesSm && (
                  <motion.section
                    initial={{ y: 500 }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "tween", duration: 0.75 }}
                    viewport={{ once: true }}
                    className={styles.trustees}
                    id="trustees"
                  >
                    <div className={styles.itemTwo}>
                      <div className={styles.info}>
                        <div> Meet Our</div> <span> Board Of </span>
                      </div>
                    </div>

                    <PersonalCard
                      expandable={false}
                      direction={"rtl"}
                      id={data[0][0]?.id}
                      name={data[0][0]?.name}
                      title={data[0][0]?.title}
                      image={data[0][0]?.image}
                    />
                    <PersonalCard
                      expandable={false}
                      direction={"rtl"}
                      id={data[0][1]?.id}
                      name={data[0][1]?.name}
                      title={data[0][1]?.title}
                      image={data[0][1]?.image}
                    />

                    <PersonalCard
                      expandable={false}
                      direction={"rtl"}
                      id={data[0][2]?.id}
                      name={data[0][2]?.name}
                      title={data[0][2]?.title}
                      image={data[0][2]?.image}
                    />

                    <PersonalCard
                      expandable={false}
                      direction={"rtl"}
                      id={data[0][3]?.id}
                      name={data[0][3]?.name}
                      title={data[0][3]?.title}
                      image={data[0][3]?.image}
                    />

                    <PersonalCard
                      expandable={false}
                      direction={"rtl"}
                      id={data[0][4]?.id}
                      name={data[0][4]?.name}
                      title={data[0][4]?.title}
                      image={data[0][4]?.image}
                    />
                  </motion.section>
                )}
                {data[1] && (
                  <motion.section
                    initial={{ y: 500 }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "tween", duration: 0.7 }}
                    viewport={{ once: true }}
                    className={styles.partners}
                    id="partners"
                  >
                    <div className={styles.header}>Our Partners</div>
                    <div className={styles.items}>
                      {data[1].map((item: any, index: number) => {
                        return (
                          <div className={styles.item} key={index}>
                            <img src={item.image} alt="partnerLogo" />
                          </div>
                        );
                      })}
                    </div>
                  </motion.section>
                )}
                {data[4] && (
                  <motion.section
                    initial={{ y: 500 }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "tween", duration: 0.7 }}
                    viewport={{ once: true }}
                    id="chart"
                  >
                    <motion.h3
                      className={styles.title}
                      style={{
                        marginBottom: "40px",
                      }}
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 0.7 }}
                      viewport={{ once: true }}
                    >
                      The Organizational Chart
                    </motion.h3>
                    <div className={styles.partnersChart}>
                      <div
                        className={`${styles.itemsContainer} ${styles.first}`}
                      >
                        <div className={`${styles.item} `}>
                          Chairman of the board of Trustees
                        </div>
                      </div>
                      <div
                        className={`${styles.itemsContainer} ${styles.second}`}
                      >
                        <div className={styles.item}>
                          Vice Chairman of the Board of Trustees
                        </div>
                        <div className={styles.box}></div>
                        <div className={`${styles.item} `}>
                          Foundation General Director (IO)
                        </div>
                      </div>
                      <div className={styles.itemsContainer}>
                        <div
                          className={styles.item}
                          style={{
                            visibility: "hidden",
                            display: matchesSm ? "none" : "block",
                          }}
                        ></div>
                        <div className={styles.box}></div>

                        <div className={`${styles.item} ${styles.third} `}>
                          Foundation Vice Director
                        </div>
                        <div className={styles.box}></div>
                        <div className={styles.item}>
                          Financial Administration
                        </div>
                      </div>
                      <div
                        className={styles.itemsContainer}
                        style={{ justifyContent: "space-between" }}
                      >
                        <div
                          className={styles.itemTwo}
                          style={{
                            zIndex:
                              overLayer.clicked && overLayer.id === 1 && matches
                                ? 9999999
                                : 0,
                          }}
                        >
                          {data[4][0]?.name}
                          {data[4][0] && data[4][0]?.team.length > 0 && (
                            <div className={styles.menu}>
                              <MembersMenu
                                team={data[4][0]?.team}
                                type={1}
                                id={data[4][0]?.id}
                                setOverLayer={setOverLayer}
                                overLayerId={1}
                              />
                            </div>
                          )}
                        </div>
                        <div
                          className={styles.itemTwo}
                          style={{
                            zIndex:
                              overLayer.clicked && overLayer.id === 2 && matches
                                ? 9999999
                                : 0,
                          }}
                        >
                          {data[4][1]?.name}
                          {data[4][1] && data[4][1]?.team.length > 0 && (
                            <div className={styles.menu}>
                              <MembersMenu
                                team={data[4][1]?.team}
                                type={1}
                                id={data[4][1]?.id}
                                setOverLayer={setOverLayer}
                                overLayerId={2}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.itemsContainer}>
                        <div
                          className={`${styles.itemTwo} ${styles.fourth}`}
                          style={{
                            zIndex:
                              overLayer.clicked && overLayer.id === 3 && matches
                                ? 9999999
                                : 0,
                          }}
                        >
                          {data[4][2]?.name}
                          {data[4][2] && data[4][2]?.team.length > 0 && (
                            <div className={styles.menu}>
                              <MembersMenu
                                team={data[4][2]?.team}
                                type={1}
                                id={data[4][2]?.id}
                                setOverLayer={setOverLayer}
                                overLayerId={3}
                              />
                            </div>
                          )}
                        </div>
                        <div className={styles.smBox}></div>
                        <div
                          className={`${styles.itemTwo} ${styles.fifth}`}
                          style={{
                            zIndex:
                              overLayer.clicked && overLayer.id === 4 && matches
                                ? 9999999
                                : 0,
                          }}
                        >
                          {data[4][3]?.name}
                          {data[4][3] && data[4][3]?.team.length > 0 && (
                            <div className={styles.menu}>
                              <MembersMenu
                                team={data[4][3]?.team}
                                type={1}
                                id={data[4][3]?.id}
                                setOverLayer={setOverLayer}
                                overLayerId={4}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </motion.section>
                )}
                {data[3] && (
                  <section className={styles.flexContainer}>
                    <motion.h3
                      initial={{ x: "-100%" }}
                      whileInView={{ x: 0 }}
                      transition={{ type: "tween", duration: 0.7 }}
                      viewport={{ once: true }}
                      className={styles.title}
                    >
                      Our Gallery
                    </motion.h3>
                    <div className={styles.sliderContainer}>
                      <Slider data={data[3]} />
                    </div>
                  </section>
                )}
              </main>
            </Container>
            <Footer />
          </>
        )
      )}
    </div>
  );
};

export default Home;
