import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import * as React from "react";
import "./components/Toast/toast.css";
import "./styles/globals.css";
import Layout from "./components/Layout/Layout";
import { MainContextProvider } from "./context/MainContext";
import cacheLtr from "./utils/createEmotionCache";
import theme from "./utils/theme";
import { Routes, Route, Router } from "react-router-dom";
import Home from "./pages/Home";
import URAF from "./pages/URAF";
import CTCLAS from "./pages/CTCLAS";
import IACUC from "./pages/IACUC";
import GalleryPage from "./pages/Gallery";
import GalleryDetailed from "./pages/Gallery/[id]";
import Error from "./pages/Error/Error";
import useScrollToTop from "./hooks/useScrollToTop";

const App: React.FunctionComponent = (props) => {
  useScrollToTop([]);
  return (
    <CacheProvider value={cacheLtr}>
      <ThemeProvider theme={theme}>
        <MainContextProvider>
          <CssBaseline />
          <Layout>
            <Routes>
              <Route path="*" element={<Error />} />
              <Route path="/" element={<Home />} />
              <Route path="/URAF" element={<URAF />} />
              <Route path="/CTCLAS" element={<CTCLAS />} />
              <Route path="/IACUC" element={<IACUC />} />
              <Route path="/Gallery">
                <Route index element={<GalleryPage />} />
                <Route path=":id" element={<GalleryDetailed />} />
              </Route>
            </Routes>
          </Layout>
        </MainContextProvider>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
