import { Container, Pagination } from "@mui/material";
import axios from "axios";
import { motion } from "framer-motion";
import queryString from "query-string";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Card from "../../components/Card/Card";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Loader from "../../components/Loader/Loader";
import NoData from "../../components/NoData/NoData";
import styles from "./Gallery.module.scss";
import useScrollToTop from "../../hooks/useScrollToTop";

const GalleryPage = () => {
  const location = useLocation();
  const params = queryString.parse(location.search);
  const page = params.page;
  const navigate = useNavigate();
  // let page = router.query.page;

  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);

  const fetchData = useCallback(async (page: any) => {
    try {
      const response = await axios.get(
        `https://eglas.org.eg/backend/api/Gallery/all?page=${page}`
      );
      setData(response.data.data);
      console.log(response);
    } catch (error) {
      console.error(error);
      toast.error("Error retrieving data from server");
    } finally {
      setLoading(false);
    }
  }, []);
  useEffect(() => {
    if (page !== undefined) {
      // Check if page is not undefined
      fetchData(page);
    }
  }, [page]);

  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", link: true, pathname: "/#vision" },
      { name: "Trustees", link: true, pathname: "/#trustees" },
      { name: "Partners", link: true, pathname: "/#partners" },
    ],
    []
  );

  useScrollToTop([]);

  return (
    <div className={styles.galleryContainer}>
      <Header navItems={navItems} />

      {loading ? (
        <Loader color="rgb(26, 171 ,174)" />
      ) : data ? (
        <>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: "90vw",
            }}
          >
            <div className={styles.gallery}>
              <motion.h3
                initial={{ x: "-100%" }}
                whileInView={{ x: "0" }}
                transition={{ type: "tween", duration: 1 }}
                viewport={{ once: true }}
              >
                Our Gallery
              </motion.h3>
              {data.data?.length > 0 ? (
                <motion.div
                  initial={{ x: "100%" }}
                  whileInView={{ x: "0" }}
                  transition={{ type: "tween", duration: 1 }}
                  viewport={{ once: true }}
                  className={styles.grid}
                >
                  {data.data?.map((item: any) => {
                    return <Card key={item.id} {...item} type="small" />;
                  })}
                </motion.div>
              ) : (
                <div>Sorry,There are no images found :(</div>
              )}
              {data.pagination.isPagination && (
                <div className={styles.pagination}>
                  <Pagination
                    showLastButton
                    showFirstButton
                    variant="outlined"
                    size="medium"
                    color="secondary"
                    count={data[0].pagination.total}
                    page={Number(page) || 1}
                    onChange={(e, page) => {
                      navigate(`/Gallery?page=${page}`);
                    }}
                  />
                </div>
              )}
            </div>
          </Container>
          <Footer />
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default GalleryPage;
