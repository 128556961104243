export function splitIntoArrays<T>(arr: T[]): T[][] {
  const result: T[][] = [];
  let tempArr: T[] = [];
  for (let i = 0; i < arr?.length; i++) {
    tempArr.push(arr[i]);
    if (tempArr.length === 4 || i === arr.length - 1) {
      result.push(tempArr);
      tempArr = [];
    }
  }
  return result;
}
