import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { AnimatePresence, motion } from "framer-motion";
import { FC, useState } from "react";
import styles from "./TrainingAccordion.module.scss";

interface Props {
  name: string;
  subTraining: any;
}

// background: linear-gradient(62.42deg, rgba(255, 255, 255, 0.1) 20.61%, rgba(255, 255, 255, 0) 82.27%);
// filter: drop-shadow(1.72407px 3.44815px 25.8611px rgba(0, 0, 0, 0.06));
// backdrop-filter: blur(30.1713px);

const TrainingAccordion: FC<Props> = ({ name, subTraining }) => {
  const [open, setOpen] = useState<boolean>(false);
  let listItems = [];
  listItems = subTraining;

  return (
    <div className={styles.accordion}>
      <Accordion
        sx={{
          boxShadow:
            "1.7240746021270752px 3.4481492042541504px 25.86111831665039px 0px #0000000F",
          borderRadius: "20px !important",
          background: "rgba(235, 235, 235, 0.43)",
        }}
      >
        <AccordionSummary
          onClick={() => {
            setOpen(!open);
          }}
          sx={{
            // backgroundColor: "white",
            background:
              "linear-gradient(90deg, rgba(112,48,160,0.03) 0%, rgba(255,255,255,1) 100%);",

            backdropFilter: "blur(30.1713px)",
            padding: { xs: "8px 16px", md: "20px 35px 20px 40px" },
            height: { xs: "70px", lg: "80px", xl: "100px" },
            boxShadow:
              "1.7240746021270752px 3.4481492042541504px 25.86111831665039px 0px #0000000F",
            borderRadius: {
              xs: "16px !important",
              lg: "18px !important",
              xl: "20px !important",
            },
            alignItems: "center",
          }}
          expandIcon={
            <div
              style={{
                width: "30px",
                display: "flex",
                height: "30px",
                justifyContent: "center",
                alignItems: " center",
                background: "#7030A012",
                borderRadius: "6.25px",
              }}
            >
              <svg
                width="16"
                height="9"
                viewBox="0 0 16 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6001 7.5419L9.16681 2.10857C8.52515 1.4669 7.47515 1.4669 6.83348 2.10857L1.40015 7.5419"
                  stroke="#7030A0"
                  strokeWidth="1.875"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              "& p": {
                fontSize: {
                  xs: "16px !important",
                  lg: "22px !important",
                  xl: "32px !important",
                },
              },
              fontWeight: "700",
            }}
          >
            {name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            padding: { xs: "10px 16px", md: "20px 35px 20px 40px" },
            background: "rgba(235, 235, 235, 0.43)",
            backdropFilter: "blur(30.1713px)",
            borderBottomRightRadius: "20px",
            borderBottomLeftRadius: "20px",
            boxShadow:
              "1.7240746021270752px 3.4481492042541504px 25.86111831665039px 0px #0000000F",
          }}
        >
          <AnimatePresence>
            {open && (
              <ul>
                {listItems.map((item: any, index: number) => {
                  return (
                    <motion.li
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.1 * index }}
                      key={item.id}
                    >
                      {item.name}
                    </motion.li>
                  );
                })}
              </ul>
            )}
          </AnimatePresence>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TrainingAccordion;
