import React, { useState, useReducer, useContext } from "react";
import MainReducer from "../reducer/MainReducer";

interface MainContextProps {
  openPopovers: any[];
  setOpenPopovers: (value: any) => void;
}
interface ChildrenProps {
  children: React.ReactNode;
}

export const MainContext = React.createContext<MainContextProps>({
  openPopovers: [],
  setOpenPopovers: () => {},
});
const initialState = {};

export const MainContextProvider = (props: ChildrenProps) => {
  const [state, dispatch] = useReducer(MainReducer, initialState);

  const [openPopovers, setOpenPopovers] = useState([]);

  return (
    <MainContext.Provider
      value={{
        openPopovers,
        setOpenPopovers,
      }}
    >
      {props.children}
    </MainContext.Provider>
  );
};

export const useMainContext = () => {
  return useContext(MainContext);
};
