import { Box, Button, CircularProgress } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import CustomSelect from "../CustomSelect/CustomSelect";
import CustomTextField from "../CustomTextField/CustomTextField";
import styles from "./ConsultingForm.module.scss";
import axios from "axios";
import { toast } from "react-toastify";

const ConsultingForm = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    institute: Yup.string().required("Institute is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string()
      .required("Phone is required")
      .min(7, "Invalid phone number"),
    service_id: Yup.number().required("Please select a service"),
  });

  const initialValues = {
    name: "",
    institute: "",
    email: "",
    phone: "",
    service_id: "",
    comment: "",
  };
  const handleSubmit = (values: any, actions: any) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    console.log(values);

    axios
      .post("https://eglas.org.eg/backend/api/CTC/consulting", formData)
      .then((response) => {
        toast.success("Form submitted successfully!");
        actions.setSubmitting(false);
        console.log(response);
        actions.resetForm();
      })
      .catch((error) => {
        toast.error("Error submitting form. Please try again.");
        actions.setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={styles.form}>
          <div className={styles.formItem}>
            <CustomTextField name="name" placeHolder="Name" />
            <CustomTextField name="institute" placeHolder="Institute" />
          </div>
          <div className={styles.formItem}>
            <CustomTextField name="email" placeHolder="Email" />
            <CustomTextField name="phone" placeHolder="Phone" type="number" />
          </div>

          <div className={styles.formItem}>
            <CustomSelect name={"service_id"} />
          </div>
          <div className={styles.formItem}>
            <CustomTextField multiline name="comment" placeHolder="Comment" />
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", md: "flex-start" },
              alignItems: "center",
              width: "100%",
            }}
          >
            <Button
              type="submit"
              variant="contained"
              color="warning"
              disabled={isSubmitting}
              endIcon={isSubmitting ? <CircularProgress size={20} /> : null}
              sx={{
                height: {
                  xs: "60px",
                  md: "60px",
                  lg: "65px",
                  xl: "75px",
                },
                width: {
                  xs: "200px",
                  md: "200px",
                  lg: "320px",
                  xl: "370px",
                },
                fontSize: {
                  xs: "20px",
                  md: "16px",
                  lg: "24px",
                  xl: "32px",
                },
                borderRadius: "10px",
                backgroundColor: "neutral.main",
              }}
            >
              Send
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ConsultingForm;
