import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import { FC, MouseEvent, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useMainContext } from "../../context/MainContext";
import ListItem from "./ListItem";
import styles from "./MembersMenu.module.scss";

interface Props {
  type: number;
  team: any[];
  id: number;
  setOverLayer?: any;
  overLayerId?: number;
}

const MembersMenu: FC<Props> = ({
  team,
  type,
  id,
  setOverLayer,
  overLayerId,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { openPopovers, setOpenPopovers } = useMainContext();
  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setOpenPopovers([...openPopovers, id]);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setOpenPopovers([]);
    if (setOverLayer) {
      setOverLayer({
        id: overLayerId,
        clicked: false,
      });
    }
  };

  const open = openPopovers.includes(id);

  const matches = useMediaQuery("(max-width:1000px)");

  return (
    <div className={styles.memberMenu}>
      {type === 1 ? (
        <Button
          // aria-describedby={id}
          variant="contained"
          onClick={(e) => {
            handleOpen(e);
            setOverLayer({
              id: overLayerId,
              clicked: true,
            });
          }}
          sx={{
            width: { xs: 35, md: 25, lg: 35, xl: 50 },
            height: { xs: 35, md: 25, lg: 35, xl: 50 },
            borderRadius: "50%",

            fontSize: { xs: 35, md: 25, lg: 35, xl: 50 },
            minWidth: "unset",
            padding: "unset",
          }}
        >
          +
        </Button>
      ) : (
        <Button
          // aria-describedby={id}
          variant="contained"
          onClick={handleOpen}
          sx={{
            width: { xs: 24, lg: 32, xl: 38 },
            height: { xs: 24, lg: 32, xl: 38 },
            borderRadius: "50%",
            transform: "translateX(50%)",
            fontSize: { xs: 12, md: 16, lg: 20, xl: 24 },
            minWidth: "unset",
            padding: "unset",
          }}
        >
          <FaChevronRight />
        </Button>
      )}
      <Popover
        id={String(id)}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          backdrop: {
            sx: {
              backdropFilter: type === 1 ? "blur(4.5px)" : "unset",
            },
          },
        }}
        sx={{
          transform: "translateX(10px)",
          ".Mui-Backdrop-root": {
            backdropFilter: "blur(4.5px)",
          },

          "& .MuiPaper-root": {
            background:
              "linear-gradient(62.42deg, rgba(255, 255, 255, 0.1) 20.61%, rgba(255, 255, 255, 0.1) 82.27%) !important",
            backdropFilter: "blur(16px)",
            width: { xs: "220px", md: "270px", xl: "330px" },
            boxShadow: "0.872549px 1.7451px 13.0882px rgba(0, 0, 0, 0.1);",
            borderRadius: "18px",
            padding: { xs: "12px 0 12px 12px", lg: "32px 0 32px 24px" },
            borderLeft: { xs: "5px solid #0976B4", lg: "10px solid #0976B4" },
            overflowX: "visible",
            overflowY: "unset",
          },
        }}
        anchorOrigin={{
          vertical: matches ? "top" : "center",
          horizontal: matches ? "left" : "right",
        }}
        transformOrigin={{
          vertical: matches ? "top" : "center",
          horizontal: "left",
        }}
        // disableScrollLock={true}
      >
        <div className={styles.menu}>
          {/* {type === 1 && (
            <div className={styles.header}>
              Members
              <Divider
                sx={{
                  borderColor: "#787878",
                  margin: { xs: "10px 0 0 0", lg: "20px 0 0 0 " },
                }}
              />
            </div>
          )} */}

          <ul>
            {team?.map((member) => {
              return <ListItem key={member.id} id={member.id} {...member} />;
            })}
          </ul>
        </div>
      </Popover>
    </div>
  );
};

export default MembersMenu;
