import { navItem } from "../../typings";
import { Container, useMediaQuery } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { motion } from "framer-motion";
// import Image from "next/image";
// import Link from "next/link";
// import { useRouter } from "next/router";
import * as React from "react";
import { FiMenu } from "react-icons/fi";
import logo from "../../assets/logo.png";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
import logo3 from "../../assets/logo3.png";
import styles from "./Header.module.scss";
import { useNavigate, useLocation, Link } from "react-router-dom";

interface Props {
  window?: () => Window;
  navItems: navItem[];
}

const drawerWidth = 240;

export default function DrawerAppBar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const matches = useMediaQuery("(max-width:1685px)");

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const navigate = useNavigate();
  const location = useLocation();
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Box
        sx={{
          margin: "20px 0",
          position: "relative",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/");
        }}
      >
        <img src={logo} alt={"logo"} width={150} height={150} />
      </Box>
      <Divider />
      <List>
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => {
            navigate("/URAF");
          }}
        >
          <ListItemText primary={<h5 style={{ margin: "10px 0" }}>URAF</h5>} />
        </ListItemButton>
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => {
            navigate("/CTCLAS");
          }}
        >
          <ListItemText
            primary={<h5 style={{ margin: "10px 0" }}>CTC-LAS</h5>}
          />
        </ListItemButton>
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => {
            navigate("/IACUC");
          }}
        >
          <ListItemText primary={<h5 style={{ margin: "10px 0" }}>IACUC</h5>} />
        </ListItemButton>
        <ListItemButton
          sx={{ textAlign: "center" }}
          onClick={() => {
            navigate("/Gallery?page=1");
          }}
        >
          <ListItemText
            primary={<h5 style={{ margin: "10px 0" }}>Gallery</h5>}
          />
        </ListItemButton>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const lastItem = location.pathname.includes("URAF") ? (
    <img width={90} height={85} src={logo1} alt="logo1" />
  ) : location.pathname.includes("CTCLAS") ? (
    <img
      width={matches ? 100 : 150}
      height={matches ? 70 : 103}
      src={logo2}
      alt="logo2"
    />
  ) : location.pathname.includes("IACUC") ? (
    <img
      width={matches ? 80 : 125}
      height={matches ? 90 : 150}
      src={logo3}
      alt="logo3"
    />
  ) : (
    <>
      <div className={styles.searchIcon}>{/* <BiSearch /> */}</div>
    </>
  );
  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <motion.header
        initial={{ y: "-100%" }}
        whileInView={{ y: 0 }}
        transition={{ duration: 0.5, type: "tween" }}
        viewport={{ once: true }}
        className={styles.header}
      >
        {/* <ElevationScroll {...props}> */}
        <AppBar
          position="static"
          component="nav"
          sx={{
            backgroundColor: "transparent",
            boxShadow: "none",
            color: "#000201",
            "& .MuiToolbar-root": {
              padding: "0",
            },
          }}
        >
          <Container
            maxWidth={false}
            sx={{ paddingTop: "25px", maxWidth: "90vw" }}
          >
            <Toolbar
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <FiMenu />
              </IconButton>
              <div
                className={styles.logo}
                onClick={() => {
                  navigate("/");
                }}
              >
                <img alt="logo" src={logo} />
              </div>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    md: "flex",
                  },
                  gap: {
                    sm: "22px",
                    md: "28px",
                    lg: "40px",
                    xl: "48px",
                  },
                }}
              >
                {props.navItems.map((item, index) => (
                  <motion.div
                    whileHover={{
                      scale: 1.2,
                      transition: { duration: 0.2 },
                    }}
                    // whileTap={{ scale: 0.9 }}
                    className={styles.navItems}
                    key={index}
                  >
                    {item.link && item.pathname ? (
                      <Link to={item.pathname}>{item.name}</Link>
                    ) : (
                      <a href={`#${item.id}`}>{item.name}</a>
                    )}
                  </motion.div>
                ))}
              </Box>
              {lastItem}
            </Toolbar>
          </Container>
        </AppBar>
        {/* </ElevationScroll> */}
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { sm: "block", md: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </motion.header>
    </div>
  );
}
