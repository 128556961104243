import { Avatar } from "@mui/material";
import React from "react";
import MembersMenu from "./MemberMenu";
import styles from "./MembersMenu.module.scss";

type Props = {
  image?: string;
  name?: string;
  title?: string;
  team?: any[];
  id: number;
};

const ListItem = ({ team, title, image, name, id }: Props) => {
  return (
    <li>
      {team && team.length > 0 && (
        <div className={styles.menu}>
          <MembersMenu type={2} team={team} id={id} />
        </div>
      )}
      <Avatar
        sx={{
          width: { xs: 45, lg: 60 },
          height: { xs: 45, lg: 60 },
        }}
        alt="person"
        src={image}
      />
      <div className={styles.info}>
        <div className={styles.name}>{name}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </li>
  );
};

export default ListItem;
