import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import axios from "axios";
import { Field, FieldProps } from "formik";
import { FC, useEffect, useState } from "react";

interface CustomSelectProps {
  name: string;
}

const CustomSelect: FC<CustomSelectProps> = ({ name }) => {
  const [items, setItems] = useState<{ id: number; name: string }[] | any>([]);

  const [loading, setLoading] = useState(false);

  const [selectedChoice, setSelectedChoice] = useState<any>("");
  useEffect(() => {
    async function fetchChoices() {
      try {
        const data = await axios.get(
          "https://eglas.org.eg/backend/api/CTC/servicesTypes"
        );
        setItems(data.data.data);
        console.log(data.data.data);

        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }
    fetchChoices();
  }, []);

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormControl
          sx={{
            width: "100%",
            backgroundColor: "white",
            boxShadow: "1px 3px 20px 0px rgba(0, 0, 0, 0.04)",

            borderRadius: "10px",
            padding: { xs: "8px", md: "2px" },
            fontSize: {
              xs: "16px",
              md: "24px",
            },
            ".MuiInputLabel-root": {
              left: "6px",
              // marginTop: "6px",
              fontSize: { xs: "16px", md: "18px", lg: "24px" },
            },
          }}
          color="warning"
        >
          <InputLabel
            id="custom-select-label"
            sx={{
              marginTop: { md: "2px", xl: "3px" },
              color: meta.touched && Boolean(meta.error) ? "red" : "gray",
            }}
          >
            Select an option
          </InputLabel>

          <Select
            labelId="custom-select-label"
            id="custom-select"
            value={field.value}
            onChange={(event) => {
              setSelectedChoice(event.target.value);
              field.onChange(event);
              // setFieldValue(name, event.target.value);
            }}
            sx={{
              backgroundColor: "white",
              ".MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                padding: {
                  xs: "5.5px 14px",
                  md: "10px 12px",
                  lg: "12px 14px",
                  xl: "16.5px 14px",
                },
              },
            }}
            color="warning"
            name={name}
          >
            {loading ? (
              <MenuItem disabled>
                <CircularProgress size={20} />
              </MenuItem>
            ) : (
              items.map((choice: any) => (
                <MenuItem key={choice.id} value={choice.id}>
                  {choice.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>
      )}
    </Field>
  );
};

export default CustomSelect;
