import React from "react";
import "./Error.scss";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <div className="error-container">
      <div className="error">
        <div className="error__title">404</div>
        <div className="error__subtitle">Sorry...</div>
        <div className="error__description">we couldn't find this page</div>
        <div className="btn-container">
          <Link className="error__button" to={"/"}>
            Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
