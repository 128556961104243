import FileSaver from "file-saver";
import { motion } from "framer-motion";
import { FC, useState } from "react";
import { toast } from "react-toastify";
import styles from "./Application.module.scss";
import { CircularProgress } from "@mui/material";

interface Props {
  id: number;
  name: string;
  link: string;
}

const Application: FC<Props> = ({ id, name, link }) => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const downloadFile = async (url: string) => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/"; // Proxy server URL
    const fileType = url.split(".").pop();

    try {
      setDownloading(true);
      const response = await fetch(proxyUrl + url, {
        method: "GET",
        headers: {
          "Content-Type": `application/${fileType}`,
        },
      });
      const blob = await response.blob();
      FileSaver.saveAs(blob, `file.${fileType}`);
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      setDownloading(false);
    }
  };

  return (
    <div className={styles.application} key={id}>
      <div className={styles.content}>{name}</div>
      <div className={styles.icons}>
        <motion.div
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.1 },
          }}
          whileTap={{ scale: 0.9 }}
          className={styles.icon}
          onClick={() => window.open(link, "_blank")}
        >
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.9999 27.2165C16.0165 27.2165 12.7832 23.9832 12.7832 19.9999C12.7832 16.0165 16.0165 12.7832 19.9999 12.7832C23.9832 12.7832 27.2165 16.0165 27.2165 19.9999C27.2165 23.9832 23.9832 27.2165 19.9999 27.2165ZM19.9999 15.2832C17.3999 15.2832 15.2832 17.3999 15.2832 19.9999C15.2832 22.5999 17.3999 24.7165 19.9999 24.7165C22.5999 24.7165 24.7165 22.5999 24.7165 19.9999C24.7165 17.3999 22.5999 15.2832 19.9999 15.2832Z"
              fill="black"
            />
            <path
              d="M20.0003 35.0341C13.7336 35.0341 7.81696 31.3674 3.75029 25.0008C1.98363 22.2508 1.98363 17.7674 3.75029 15.0008C7.83363 8.6341 13.7503 4.96744 20.0003 4.96744C26.2503 4.96744 32.167 8.6341 36.2336 15.0008C38.0003 17.7508 38.0003 22.2341 36.2336 25.0008C32.167 31.3674 26.2503 35.0341 20.0003 35.0341ZM20.0003 7.46744C14.617 7.46744 9.46696 10.7008 5.86696 16.3508C4.61696 18.3008 4.61696 21.7008 5.86696 23.6508C9.46696 29.3008 14.617 32.5341 20.0003 32.5341C25.3836 32.5341 30.5336 29.3008 34.1336 23.6508C35.3836 21.7008 35.3836 18.3008 34.1336 16.3508C30.5336 10.7008 25.3836 7.46744 20.0003 7.46744Z"
              fill="black"
            />
          </svg>
        </motion.div>
        <motion.div
          whileHover={{
            scale: 1.1,
            transition: { duration: 0.1 },
          }}
          whileTap={{ scale: 0.9 }}
          className={
            downloading ? `${styles.icon} ${styles.download}` : styles.icon
          }
          onClick={() => downloadFile(link)}
        >
          {!downloading ? (
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7996 24.9837C19.4829 24.9837 19.1663 24.8671 18.9163 24.6171L14.6496 20.3504C14.1663 19.8671 14.1663 19.0671 14.6496 18.5837C15.1329 18.1004 15.9329 18.1004 16.4163 18.5837L19.7996 21.9671L23.1829 18.5837C23.6663 18.1004 24.4663 18.1004 24.9496 18.5837C25.4329 19.0671 25.4329 19.8671 24.9496 20.3504L20.6829 24.6171C20.4329 24.8671 20.1163 24.9837 19.7996 24.9837Z"
                fill="black"
              />
              <path
                d="M19.8018 24.8664C19.1184 24.8664 18.5518 24.2997 18.5518 23.6164V6.66635C18.5518 5.98302 19.1184 5.41635 19.8018 5.41635C20.4851 5.41635 21.0518 5.98302 21.0518 6.66635V23.6164C21.0518 24.2997 20.4851 24.8664 19.8018 24.8664Z"
                fill="black"
              />
              <path
                d="M20.0008 34.8825C11.4175 34.8825 5.41748 28.8825 5.41748 20.2992C5.41748 19.6158 5.98415 19.0492 6.66748 19.0492C7.35081 19.0492 7.91748 19.6158 7.91748 20.2992C7.91748 27.4158 12.8841 32.3825 20.0008 32.3825C27.1175 32.3825 32.0841 27.4158 32.0841 20.2992C32.0841 19.6158 32.6508 19.0492 33.3341 19.0492C34.0175 19.0492 34.5841 19.6158 34.5841 20.2992C34.5841 28.8825 28.5841 34.8825 20.0008 34.8825Z"
                fill="black"
              />
            </svg>
          ) : (
            <CircularProgress size={20} color="inherit" />
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default Application;
