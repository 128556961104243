import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: `"Cardo", "Helvetica", "Arial", sans-serif`,
    fontSize: 24,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1350,
      xl: 1685,
    },
  },
  palette: {
    primary: {
      main: "#0976B4",
    },
    secondary: {
      main: "rgba(26, 171, 174, 1)",
    },
    info: {
      main: "#ffffff",
    },
    warning: {
      main: "#7030A0",
    },
    error: {
      main: "#C00000",
    },
  },
});

export default theme;
