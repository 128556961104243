import { FC } from "react";
import { ClipLoader } from "react-spinners";

interface LoaderProps {
  color: string;
}

const Loader: FC<LoaderProps> = ({ color }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "50vh",
        width: "100vw",
      }}
    >
      <ClipLoader size={100} color={color} />
    </div>
  );
};

export default Loader;
