import { useMediaQuery } from "@mui/material";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Toast = () => {
  const matches = useMediaQuery("(max-width:600px)");

  return (
    <>
      <ToastContainer
        position={matches ? "bottom-center" : "bottom-right"}
        autoClose={1500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Toast;
