import { FC } from "react";
import { FaChevronRight } from "react-icons/fa";
import styles from "./Card.module.scss";
import { useNavigate } from "react-router-dom";

interface CardProps {
  type?: "small";
  icon: string;
  title: string;
  id: number;
}

const Card: FC<CardProps> = ({ type, icon, title, id }) => {
  const navigate = useNavigate();
  return (
    <div
      className={
        type === "small" ? `${styles.small} ${styles.card}` : styles.card
      }
      onClick={() => {
        navigate(`/Gallery/${id}`);
      }}
      key={id}
    >
      <img src={icon} alt="card" className={styles.image} />
      <p className={styles.title}>
        {/* URAF is your preferred laboratory animal facility */}
        {title}
      </p>
      <div className={styles.icon}>
        <FaChevronRight />
      </div>
      <div className={styles.layer}></div>
    </div>
  );
};

export default Card;
