import { motion } from "framer-motion";
import React, { FC, useState } from "react";

interface AnimationProps {
  children?: React.ReactNode;
  duration: number;
  className?: string;
  reverse?: boolean;
  stopOnHover?: boolean;
}

const AnimateY: FC<AnimationProps> = ({
  children,
  duration,
  className,
  reverse,
  stopOnHover,
}) => {
  const [isAnimating, setIsAnimating] = useState<boolean>(true);

  const handleHoverStart = () => {
    setIsAnimating(false);
  };

  const handleHoverEnd = () => {
    setIsAnimating(true);
  };

  return (
    <motion.div
      className={className}
      initial={{ y: reverse ? 10 : -10 }}
      animate={
        stopOnHover
          ? isAnimating
            ? { y: reverse ? -10 : 10 }
            : {}
          : { y: reverse ? -10 : 10 }
      }
      transition={{
        repeat: stopOnHover ? (isAnimating ? Infinity : 0) : Infinity,
        repeatType: "reverse",
        type: "tween",
        duration: duration,
      }}
      onHoverStart={handleHoverStart}
      onHoverEnd={handleHoverEnd}
    >
      {children}
    </motion.div>
  );
};

export default AnimateY;
