import { useMediaQuery } from "@mui/material";
import { motion } from "framer-motion";
import { FC } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import Card from "../Card/Card";
import styles from "./Slider.module.scss";

interface Props {
  data: any;
}

const Slider: FC<Props> = ({ data }) => {
  const matchesSm = useMediaQuery("(min-width:900px)");

  return (
    <motion.div
      initial={{ x: "100%" }}
      whileInView={{ x: 0 }}
      transition={{ type: "tween", duration: 0.5 }}
      viewport={{ once: true }}
      className={styles.swiperContainer}
    >
      <Swiper
        autoplay={{ delay: 5000, reverseDirection: false }}
        // slidesPerView={!matchesSm ? 2 : 4}
        onSlideChange={() => console.log("slide change")}
        onSwiper={(swiper) => console.log(swiper)}
        breakpoints={{
          1350: {
            slidesPerView: 3.3,
            spaceBetween: 1,
          },
          1000: {
            slidesPerView: 3.75,
            spaceBetween: 1,
          },

          600: {
            slidesPerView: 2.5,
            spaceBetween: 10,
          },
          400: {
            slidesPerView: 1.75,
            spaceBetween: 0,
          },
          0: {
            slidesPerView: 1.25,
            spaceBetween: 0,
          },
        }}
      >
        {data &&
          data?.map((item: any, index: number) => {
            return (
              <SwiperSlide key={index}>
                <div className={index % 2 === 0 ? styles.odd : styles.even}>
                  <Card type={!matchesSm ? "small" : undefined} {...item} />
                </div>
              </SwiperSlide>
            );
          })}
      </Swiper>
    </motion.div>
  );
};

export default Slider;
