import { useState, useEffect } from "react";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface DataProps {
  [key: string]: any;
  // priceList?: string;
}

interface ResponseProps {
  data: DataProps;
}

const useGetData = (urls: string[]) => {
  const [data, setData] = useState<DataProps[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const baseUrl = "https://eglas.org.eg/backend/api";

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responses: AxiosResponse<ResponseProps>[] = await Promise.all(
          urls.map((url) => axios.get(baseUrl + url))
        );
        const data = responses.map((response) => response.data.data);
        setData(data);
        console.log("data", data);
      } catch (error) {
        console.error(error);
        toast.error("Error retrieving data from server");
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [urls]);

  return { data, loading };
};

export default useGetData;
