import React, { FC } from "react";
import styles from "./TextCard.module.scss";
interface TextCardProps {
  title: string;
  content: string;
  color: string;
}
const TextCard: FC<TextCardProps> = ({ title, content, color }) => {
  return (
    <section className={styles.textCard}>
      <h1>{title}</h1>
      <p>{content}</p>
      <div
        className={styles.top}
        style={{
          borderColor: color,
          color: color,
        }}
      >
        <div
          className={styles.round}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div
          className={styles.round}
          style={{
            backgroundColor: color,
          }}
        ></div>
      </div>
      <div
        className={styles.top}
        style={{
          borderColor: color,
          color: color,
        }}
      >
        <div
          className={styles.round}
          style={{
            backgroundColor: color,
          }}
        ></div>
        <div
          className={styles.round}
          style={{
            backgroundColor: color,
          }}
        ></div>
      </div>
    </section>
  );
};

export default TextCard;
