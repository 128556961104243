import { Container } from "@mui/material";
import axios, { AxiosResponse } from "axios";
import { motion } from "framer-motion";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";

import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../components/Header/Header";
import NoData from "../../components/NoData/NoData";
import styles from "./Gallery.module.scss";
import useScrollToTop from "../../hooks/useScrollToTop";
interface DataProps {
  [key: string]: any;
}
interface ResponseProps {
  data: DataProps;
}

const GalleryDetailed = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [data, setData] = useState<DataProps | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const fetchData = async (id: any) => {
    try {
      const response: AxiosResponse<ResponseProps> = await axios.get(
        `https://eglas.org.eg/backend/api/Gallery/single?id=${id}`
      );
      setData(response.data);
    } catch (error) {
      toast.error("Error retrieving data from server");
    } finally {
      setLoading(false);
    }
  };

  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", link: true, pathname: "/#vision" },
      { name: "Trustees", link: true, pathname: "/#trustees" },
      { name: "Partners", link: true, pathname: "/#partners" },
      { name: "Centers", link: true, pathname: "/#centers" },
    ],
    []
  );

  useEffect(() => {
    if (id) {
      fetchData(id);
    } else {
      return;
    }
  }, [id]);
  useScrollToTop([]);
  return (
    <div className={styles.galleryContainer}>
      <Header navItems={navItems} />
      {loading ? (
        <Loader color="rgb(26, 171 ,174)" />
      ) : data ? (
        <>
          <Container maxWidth={false} sx={{ maxWidth: "90vw" }}>
            <div className={styles.galleryDetailed}>
              <div className={styles.main}>
                <section className={styles.banner}>
                  <motion.div
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "tween", duration: 0.6 }}
                    viewport={{ once: true }}
                    style={{ position: "relative" }}
                  >
                    {data.data?.single?.icon && (
                      <div className={styles.bannerContainer}>
                        <img
                          src={data.data?.single?.icon}
                          alt="banner"
                          className={styles.banner}
                        />
                      </div>
                    )}
                  </motion.div>

                  <motion.div
                    initial={{ y: "50%" }}
                    whileInView={{ y: 0 }}
                    transition={{ type: "tween", duration: 0.4 }}
                    viewport={{ once: true }}
                  >
                    <h3>URAF is your preferred laboratory animal facility</h3>
                    <p>{data.data?.single.description}</p>
                  </motion.div>
                </section>
              </div>
              {data.data?.single.images.length > 0 && (
                <motion.div
                  initial={{ y: "20%" }}
                  whileInView={{ y: 0 }}
                  transition={{ type: "tween", duration: 0.6 }}
                  viewport={{ once: true }}
                  className={styles.sub}
                >
                  {data.data?.single.images?.map(
                    (item: { id: number; image: string }) => {
                      return (
                        <div className={styles.imageContainer} key={item.id}>
                          <img src={item.image} alt="banner" />
                        </div>
                      );
                    }
                  )}
                </motion.div>
              )}
            </div>
            {data.data?.related?.length > 0 && (
              <>
                <motion.h4
                  initial={{ x: "-100%" }}
                  whileInView={{ x: 0 }}
                  transition={{ type: "tween", duration: 0.75 }}
                  viewport={{ once: true }}
                >
                  More related photos
                </motion.h4>
                <motion.div
                  initial={{ y: "100%" }}
                  whileInView={{ y: 0 }}
                  transition={{ type: "tween", duration: 0.75 }}
                  viewport={{ once: true }}
                  className={styles.subImages}
                >
                  {data.data?.related.map((item: any, index: number) => {
                    return (
                      <div
                        className={styles.imgContainer}
                        key={item.id || index}
                        onClick={() => {
                          navigate(`/Gallery/${item.id}`);
                        }}
                      >
                        <img
                          // width={365}
                          // height={255}
                          src={item?.icon}
                          alt="banner"
                          className={styles.subImage}
                        />
                        <div className={styles.title}>{item.title} </div>
                        <div className={styles.layer}></div>
                      </div>
                    );
                  })}
                </motion.div>
              </>
            )}
          </Container>
          <Footer />
        </>
      ) : (
        <NoData />
      )}
    </div>
  );
};

export default GalleryDetailed;
