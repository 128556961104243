import { Button } from "@mui/material";
import styles from "./NoData.module.scss";

const NoData = () => {
  const reloadPage = () => {
    window.location.reload();
  };
  return (
    <div className={styles.NoData}>
      {" "}
      <div className={styles.errorContainer}>
        <div className={styles.error}>
          <div className={styles.errorTitle}>404</div>
          <div className={styles.errorSubtitle}>Sorry...</div>
          <div className={styles.errorDescription}>
            we couldn't retrieve the data from the server
          </div>
          <div className={styles.btnContainer}>
            <Button variant="outlined" color="error" onClick={reloadPage}>
              Try again
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoData;
