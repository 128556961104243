import React, { FC } from "react";
import Footer from "../Footer/Footer";
import Toast from "../Toast/Toast";
import styles from "./Layout.module.scss";

type layoutProps = {
  children?: React.ReactNode;
};

const Layout: FC<layoutProps> = ({ children }) => {
  return (
    <div className={styles.Layout}>
      <div className={styles.content}>{children}</div>

      <Toast />
    </div>
  );
};

export default Layout;
