import { Avatar, Collapse } from "@mui/material";
import { motion } from "framer-motion";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import styles from "./PersonalCard.module.scss";

interface PersonalCardProps {
  id: number;
  expandable: boolean;
  direction: string;
  expanded?: null | number;
  setOpenCard?: Dispatch<SetStateAction<number | null>>;
  name: string;
  title: string;
  image: string;
}

const PersonalCard: FC<PersonalCardProps> = ({
  id,
  expandable,
  direction,
  expanded,
  setOpenCard,
  name,
  title,
  image,
}) => {
  const [animationKey, setAnimationKey] = useState<number | null>(null);
  return (
    <div
      className={
        expandable
          ? `${styles.personalCard} ${styles.small}`
          : styles.personalCard
      }
      style={{
        direction: direction === "rtl" ? "rtl" : "ltr",
      }}
    >
      <Avatar
        sx={{
          width: {
            xs: 100,
            sm: expandable ? 90 : 140,
            lg: 120,
            xl: expandable ? 140 : 180,
          },
          height: {
            xs: 100,
            sm: expandable ? 90 : 140,
            lg: 120,
            xl: expandable ? 140 : 180,
          },
          "& img": {
            objectFit: "initial",
          },
        }}
        alt="person"
        src={image}
      />
      <Collapse
        orientation="horizontal"
        in={expandable === false ? true : id === expanded}
      >
        <div className={styles.info}>
          <div className={styles.name}>{name}</div>
          <div className={styles.job}>{title}</div>
        </div>
      </Collapse>
      {expandable && (
        <motion.div
          className={styles.icon}
          onClick={() => {
            if (expanded === id) {
              if (setOpenCard) {
                setOpenCard(null);
              }
            } else {
              setOpenCard && setOpenCard(id);
            }
            if (animationKey) {
              setAnimationKey(null);
            } else {
              setAnimationKey(1);
            }
          }}
          key={animationKey}
          initial={{ rotate: expanded === id ? 0 : 180 }}
          animate={{ rotate: expanded === id ? 180 : 360 }}
          transition={{ duration: 0.3 }}
        >
          <FaChevronRight />
        </motion.div>
      )}
    </div>
  );
};

export default PersonalCard;
