import BgCircle from "../../components/BgCircle/BgCircle";
import Footer from "../../components/Footer/Footer";
import Loader from "../../components/Loader/Loader";
import MediaCard from "../../components/MediaCard/MediaCard";
import OurTeam from "../../components/OurTeam/OurTeam";
import ServicesSlider from "../../components/Slider/ServicesSlider";
import TextCard from "../../components/TextCard/TextCard";
import useGetData from "../../hooks/useGetData";
import { Box, Button, CircularProgress } from "@mui/material";
import { Container } from "@mui/system";
import FileSaver from "file-saver";
import { motion } from "framer-motion";
import containsNull from "../../helpers/containsNull";
import { useEffect, useMemo, useState } from "react";
import { FiDownload } from "react-icons/fi";
import { toast } from "react-toastify";
import Header from "../../components/Header/Header";
import styles from "./URAF.module.scss";
import { useLocation } from "react-router-dom";
import NoData from "../../components/NoData/NoData";
import useScrollToTop from "../../hooks/useScrollToTop";

const URAF = () => {
  const navItems = useMemo(
    () => [
      {
        name: "Home",
        link: true,
        pathname: "/",
      },
      { name: "Vision & Mission", id: "vision" },
      { name: "Services", id: "services" },
      { name: "Service Application Form", id: "application" },
      { name: "Our Team", id: "team" },
    ],
    []
  );
  const urls: string[] = useMemo(
    () => ["/URAF/mainInfo", "/URAF/ourServices", "/URAF/ourTeam"],
    []
  );
  const { data, loading } = useGetData(urls);
  const location = useLocation();
  const [downloading, setDownloading] = useState<boolean>(false);
  useEffect(() => {
    const node = document.getElementById(location.hash.split("#")[1]);
    window.scrollTo({
      top: node?.offsetTop,
      behavior: "smooth",
    });
  }, [loading, location.hash]);
  useScrollToTop([loading]);
  const downloadFile = async (url: string) => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/"; // Proxy server URL
    const fileType = url.split(".").pop();

    try {
      setDownloading(true);
      const response = await fetch(proxyUrl + url, {
        method: "GET",
        headers: {
          "Content-Type": `application/${fileType}`,
        },
      });
      const blob = await response.blob();
      FileSaver.saveAs(blob, `file.${fileType}`);
    } catch (error) {
      toast.error("Error downloading file");
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <BgCircle
        bg={"rgba(26, 171, 174, 0.2)"}
        radius={1250}
        left={"-50vw"}
        top={"-64vh"}
      />

      <BgCircle
        bg={"rgba(26, 171, 174, 0.2)"}
        radius={1250}
        left={"-50vw"}
        top={"145vh"}
      />

      <BgCircle
        bg={"rgba(26, 171, 174, 0.2)"}
        radius={1250}
        left={"-50vw"}
        bottom={"0px"}
      />

      <div className={styles.uraf}>
        <Header navItems={navItems} />
        {loading ? (
          <Loader color="rgb(26, 171 ,174)" />
        ) : containsNull(data) ? (
          <NoData />
        ) : (
          data && (
            <>
              <Container
                maxWidth={false}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { xs: "100px", md: "120px", lg: "150px", xl: "180px" },
                  maxWidth: "90vw",
                }}
              >
                <div className={styles.header}>
                  <motion.div
                    initial={{ x: "-100%" }}
                    whileInView={{ x: "0" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                  >
                    <MediaCard media="image" image={data[0].image} />
                  </motion.div>
                  <motion.div
                    initial={{ x: "100%", opacity: "0" }}
                    whileInView={{ x: 0, opacity: "1" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.description}
                  >
                    <h2>{data[0]?.title}</h2>
                    <h2>URAF</h2>
                    <p>{data[0]?.headerDesc}</p>
                  </motion.div>
                </div>
                <section className={styles.vision} id="vision">
                  <motion.h3
                    initial={{ x: "-100%" }}
                    whileInView={{ x: 0 }}
                    transition={{ type: "tween", duration: 0.75 }}
                    viewport={{ once: true }}
                  >
                    Vision & Mission
                  </motion.h3>
                  {data[0] && (
                    <div className={styles.content}>
                      <motion.div
                        initial={{ x: "-100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color="#1AABAE"
                          title="Vision"
                          content={data[0]?.vision}
                        />
                      </motion.div>
                      <motion.div
                        initial={{ x: "100%" }}
                        whileInView={{ x: 0 }}
                        viewport={{ once: true }}
                        transition={{ type: "tween", duration: 0.75 }}
                      >
                        <TextCard
                          color="#1AABAE"
                          title="Mission"
                          content={data[0]?.mission}
                        />
                      </motion.div>
                    </div>
                  )}
                </section>
                {data[1] && (
                  <section className={styles.services} id="services">
                    <motion.h3
                      initial={{ x: "-100%", opacity: "0" }}
                      whileInView={{ x: 0, opacity: "1" }}
                      transition={{ type: "tween", duration: 0.6 }}
                      viewport={{ once: true }}
                    >
                      Our Service
                    </motion.h3>
                    <div className={styles.sliderContainer}>
                      <motion.div
                        initial={{ x: "100%" }}
                        whileInView={{ x: 0 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <ServicesSlider
                          reverse={false}
                          slides={data[1].slice(
                            0,
                            Math.floor(data[1].length / 2)
                          )}
                        />
                      </motion.div>
                      <motion.div
                        initial={{ x: "-100%" }}
                        whileInView={{ x: 0 }}
                        transition={{ type: "tween", duration: 0.5 }}
                        viewport={{ once: true }}
                      >
                        <ServicesSlider
                          reverse={true}
                          slides={data[1].slice(Math.floor(data[1].length / 2))}
                        />
                      </motion.div>
                    </div>
                  </section>
                )}
                <section className={styles.price} id="application">
                  <motion.div
                    initial={{ x: "-100%", opacity: "0" }}
                    whileInView={{ x: 0, opacity: "1" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.description}
                  >
                    <h3>Service Application Form</h3>
                    <p>{data[0]?.priceListDesc}</p>
                    <div className={styles.btnContainer}>
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          alignSelf: { xs: "center", md: "self-start" },
                          height: { xs: "50px", lg: "75px", xl: "90px" },
                          padding: { xl: "0 35px", xs: "0 25px" },
                          color: "white",
                          borderRadius: "45px",
                          marginTop: "25px",
                          letterSpacing: "-0.639376px;",
                          fontSize: {
                            xs: "14px",
                            md: "16px",
                            lg: "20px",
                            xl: "24px",
                          },
                          maxWidth: "250px",
                          "& .MuiButton-startIcon": {
                            "& svg": {
                              fontSize: {
                                xs: "18px",
                                lg: "20px",
                                xl: "25px",
                              },
                            },
                          },
                        }}
                        disabled={downloading}
                        startIcon={
                          downloading ? (
                            <CircularProgress size="20px" color="inherit" />
                          ) : (
                            <FiDownload />
                          )
                        }
                        onClick={() => {
                          downloadFile(data[0]?.priceList);
                        }}
                      >
                        Download
                      </Button>
                    </div>
                  </motion.div>
                  <motion.div
                    initial={{ x: "100%", opacity: "0" }}
                    whileInView={{ x: 0, opacity: "1" }}
                    transition={{ type: "tween", duration: 1 }}
                    viewport={{ once: true }}
                    className={styles.media}
                  >
                    <div className={styles.border}>
                      <svg
                        width="308"
                        height="517"
                        viewBox="0 0 308 517"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M215.132 510.626C270.598 455.19 301.758 380.002 301.758 301.603C301.758 223.204 270.598 148.017 215.132 92.5802C159.667 37.1438 84.4399 6.00001 6 6"
                          stroke="#1AABAE"
                          strokeWidth="12"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                    <img
                      src={data[0]?.priceListImage}
                      alt="logo"
                      className={styles.image}
                    />
                  </motion.div>
                </section>
                {data[2] && <OurTeam members={data[2]} />}
              </Container>
              <Footer />
            </>
          )
        )}
      </div>
    </Box>
  );
};

export default URAF;
